import { ThemeService } from "src/app/services/themes.service";
import { APPLICATION_MODE, IAppConf } from "../app.state/app.model";
import { environment } from "src/environments/environment";

export const EMPTY_LAYER_NODE_ID: string = '-1';
export const statusIconSrc: string = 'assets/layers-icons/status_all.new.png';
export let statusIconSrcForPanel: string = 'assets/layers-icons/status_all.svg';
export const scanIconSrcForPanel: string = 'assets/layers-icons/scan_layer.svg';
export const statusIconSrcForLayerSelection: string = 'assets/layers-icons/status_all_for_layer_selection.svg';
export let checklistIconSrcForPanel: string = 'assets/layers-icons/checklist_all.svg';

export const ADDRESS_LAYER_ICON: string = 'assets/layers-icons/address_pin_layer.png';
export const ADDRESS_LAYER_ICON_FOR_PANEL: string = 'assets/layers-icons/address_pin_layer.svg';
export const PANORMIC_LAYER_ICON: string = 'assets/layers-icons/panoramic.png';
export const PANORMIC_LAYER_ICON_FOR_PANEL: string = 'assets/layers-icons/panoramic.svg';
export const QR_CODE_LAYER_ICON: string = 'assets/layers-icons/qr-code_layer.gif';
export let QR_CODE_LAYER_ICON_FOR_PANEL: string = 'assets/layers-icons/qr-code_layer.svg';

export const STATUS_LAYER_ICON_PRE: string = `${environment.baseHref}/assets/status_pm/status_placemark_`;
export const SCAN_PM_ICON: string = `${environment.baseHref}/assets/scan_pm/scan_pm.png`;

export function initializeThemeBasedIcons(themeService: ThemeService): void {
    themeService.getAppConf().subscribe((appConf: IAppConf) => {
        statusIconSrcForPanel = appConf.appMode === APPLICATION_MODE.STANDALONE ? 'assets/layers-icons/status_all.svg' : 'assets/layers-icons/status_all.new.png';
        checklistIconSrcForPanel = appConf.appMode === APPLICATION_MODE.STANDALONE ? 'assets/layers-icons/checklist_all.svg' : 'assets/layers-icons/checklist_all.black.svg';
        QR_CODE_LAYER_ICON_FOR_PANEL = appConf.appMode === APPLICATION_MODE.STANDALONE ? 'assets/layers-icons/qr-code_layer.svg' : 'assets/layers-icons/qr-code_layer.png';
    });
};