import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntositeEmbeddedService } from 'src/app/services/external/intosite-embedded.service';

@Component({
  selector: 'ins-embedded-app-landing',
  templateUrl: './embedded-app-landing.component.html',
  styleUrls: ['./embedded-app-landing.component.scss']
})
export class EmbeddedAppLandingComponent implements OnInit {

  constructor(private intositeEmbeddedService: IntositeEmbeddedService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.intositeEmbeddedService.performIntositeLogin.subscribe((performLogin: boolean) => {
      if (performLogin) {
        const sessionId: string = localStorage.getItem('intosite_session');
        if (sessionId) {
          this.intositeEmbeddedService.embeddedLoginDone.next({alreadyLoggedIn: true});
        } else {
          this.router.navigate(['login'], {queryParams: this.route.snapshot.queryParams});
        }
      }
    });
  }
}
